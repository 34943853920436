<template>
  <div class="page">
    <div class="topbar clearfix">
      <div class="decorate"></div>
      <div class="main">选择会员类型</div>
      <div class="other" @click="toOrder">购买记录</div>
    </div>
    <div class="list">
      <div class="list-item" v-for="item in goodsList" v-bind:key="item.id">
        <p class="item-info" @click="toGoods(item)">
          {{ item.name }}
          <img src="../../assets/centericon_14.png" alt />
        </p>
      </div>
    </div>
    <!-- <div
      class="backButton"
      v-if="redirectUrl"
      :style="{top:y+'px',left:x+'px'}"
      @touchmove="moveing"
      @click.stop="backButton"
    >
      <img v-if="redirectUrl" src="../../assets/returnlogo.png" @click.stop="openButton" />
    </div> -->
  </div>
</template>
<script>
import { getUserInfo, goodsList, sendToken } from '@/api/member'

import { Toast } from 'vant'
let vm = null
export default {
  name: 'goods',
  metaInfo: {
    meta: [
      { charset: 'utf-8' },
      {
        name: 'divport',
        content:
          'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0',
      },
    ],
  },
  data() {
    return {
      appCode: '',
      goodsCode: '',
      userInfo: {},
      goodsList: [],
      goodsListLength: [],
      maxX: document.documentElement.clientWidth - 50,
      maxY: document.documentElement.clientHeight - 80,
      redirectUrl: '',
      successGoBack: '', // 随便查（taxlaw）传来的参数 传递给goods
      minX: 0,
      minY: 0,
      x: 100,
      y: 100,
      isOpen: false,
    }
  },
  // beforeRouteEnter(to, from, next) {
  //   if(from.name == 'goods' && from.to == 'goodslist') {
  //     next(() => {
  //       window.histyoy.go(-2);
  //     });
  //   }
  //   window.console.log(to, "组件独享守卫beforeRouteEnter第一个参数");
  //   window.console.log(from, "组件独享守卫beforeRouteEnter第二个参数");
  //   window.console.log(next, "组件独享守卫beforeRouteEnter第三个参数");
  // },
  created: function() {
    this.x = this.maxX
    this.y = this.maxY - 50
    vm = this
    vm.appCode = vm.$route.query.appCode //应用code
    vm.redirectUrl = vm.$route.query.redirectUrl

    // 随便查（taxlaw）传来的参数 传递给goods
    vm.successGoBack = vm.$route.query.successGoBack

    vm.getIsLogin()
    //获取链接上携带的参数
    /*let newCookie = this.$route.query.token
    if (newCookie) {
      //存在cookie 就保存一下
     // document.cookie = 'ZSDY_JSESSION_SID=' + newCookie
     let newObj = {
        session_id:vm.$route.query.token,
        ticket:vm.$route.query.token,
        expires_in:vm.$route.query.expiresIn,
      }
      if(vm.$route.query.expiresIn)newObj.expires_in = vm.$route.query.expiresIn
      //调用奇怪的接口 2024-03-06增加
      vm.getSetCookie(newObj)

    }else{
      
    }*/
  },
  watch: {},
  mounted() {},
  methods: {
    //返回上一页
    back() {
      this.$router.go(-1)
    },
    toGoods(item) {
      window.console.log(item)
      var data = {
        appCode: this.appCode,
        goodsCode: item.code,
      }
      this.$router.push({ path: '/goods', query: data })
    },
    //获取用户信息
    getIsLogin() {
      // eslint-disable-next-line no-debugger

      getUserInfo().then((res) => {
        if (res.code === 401) {
          // let url = "/api/snslogin/wxgzh?redirect_uri=" + encodeURIComponent(location.href);
          window.console.log(
            '/api/snslogin/wxgzh?redirect_uri=' +
              encodeURIComponent(encodeURIComponent(location.href))
          )
          // window.location.replace(url);
          window.location.href =
            '/api/snslogin/wxgzh?redirect_uri=' +
            encodeURIComponent(encodeURIComponent(location.href))
          // document.cookie = ''
        }
        this.userInfo = res.data
      })
      // this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"))
      Toast.loading({
        message: '加载中...',
        forbidClick: true,
        duration: 500,
        mask: true,
      })
      this.getData(this.appCode)
    },
    //添加cookie
    getSetCookie(obj) {
      window.console.log(obj)
      sendToken(obj)
        .then((res) => {
          window.console.log(res)
          vm.getIsLogin()
        })
        .catch((err) => {
          window.console.log(err)
        })
    },
    //获取商品
    getData(appCode) {
      let that = this
      let data = { appCode: appCode } //商品
      goodsList(data).then((res) => {
        that.goodsList = res.data
        that.goodsListLength = res.data.length
        if (that.goodsListLength != '1') {
          //判断商品个数
          that.$router.replace({
            path: '/goodsList',
            query: {
              appCode: appCode,
              redirectUrl: that.redirectUrl,
              successGoBack: that.successGoBack,
            },
          })
        } else {
          that.$router.replace({
            path: '/newgoods',
            query: {
              appCode: appCode,
              goodsCode: res.data[0].code,
              redirectUrl: that.redirectUrl,
              successGoBack: that.successGoBack,
            },
          })
        }
      })
    },
    //跳转购买记录
    toOrder() {
      let that = this
      if (that.userInfo.mobile === undefined) {
        that.show = true //未绑定手机号的弹窗
      } else if (that.userInfo.mobile === '') {
        that.show = true //未绑定手机号的弹窗
      } else {
        var data = {
          appId: this.appId,
          appCode: this.appCode,
        }
        this.$router.push({ path: '/order', query: data })
      }
    },
    //返回按钮开关
    openButton() {
      if (this.isOpen) {
        this.x = this.maxX
        this.isOpen = false
      } else {
        this.x = this.maxX - 90
        this.isOpen = true
      }
    },
    //点击返回相关app
    backButton() {
      window.console.log(this.redirectUrl)
      window.location.href = this.redirectUrl
    },
    //图标拖动事件
    moveing(e) {
      e.preventDefault()
      let y = e.changedTouches[0].clientY - 31 //减去高度的一半， 使手指与图标居中
      if (this.minY < y && y < this.maxY) {
        this.y = y
      } else if (y < this.minY) {
        this.y = 0
      } else if (y > this.maxY) {
        this.y = this.maxY
      }
    },
  },
}
</script>

<style scoped>
.page {
  background-color: #f6f7f9;
}
.topbar {
  background-color: #fff;
  padding: 15px;
}
.decorate {
  background-color: #298afd;
  width: 4px;
  height: 16px;
  border-radius: 5px;
  display: inline-block;
  margin-right: 5px;
  margin-top: 3px;
}
.main {
  font-size: 16px;
  display: inline-block;
  vertical-align: top;
}

.other {
  display: inline-block;
  color: #777;
  float: right;
  font-size: 16px;
}
.list {
  background-color: #fff;
  margin-top: 15px;
  padding: 1px 15px;
}
.list-item {
  margin: 25px 0;
  height: 67px;
}
.item-info {
  position: relative;
  padding-left: 85px;
  line-height: 67px;
}

.item-info img {
  width: 12px;
  height: 22px;
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
}
.list-item:nth-of-type(3n-2) {
  background: url(../../assets/bj1.png) no-repeat;
  background-size: 100% 100%;
  color: #083e7d;
}

.list-item:nth-of-type(3n-1) {
  background: url(../../assets/bj2.png) no-repeat;
  background-size: 100% 100%;
  color: #7c4813;
}

.list-item:nth-of-type(3n) {
  background: url(../../assets/bj3.png) no-repeat;
  background-size: 100% 100%;
  color: #390664;
}
.backButton {
  position: fixed;
  width: 150px;
  height: 62px;
  background: url('../../assets/return.png');
  background-size: contain;
  z-index: 999;
  transition: left 1.5s;
}
.backButton > img {
  position: relative;
  top: 50%;
  left: 5px;
  transform: translateY(-50%);
  width: 40px;
  height: 40px;
}
</style>
